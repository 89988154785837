














import { Component, Prop, Vue } from 'vue-property-decorator';

export const priorityBreakpoints = [320, 480, 640, 800, 960, 1120];

@Component
export default class TableColumnSelector extends Vue {
  @Prop(Array) readonly fields?: Array<any>;

  windowWidth: number = 0;

  get conditionalFields(): Array<any> {
    return this.fields!.filter((f) => this.isConditional(f));
  }

  toggleVisibility(field: any) {
    const isVisible = this.isVisible(field);
    const classList = (field.class || '')
      .split(' ')
      .filter((className: string) => ['display-always-hidden', 'display-always-visible'].indexOf(className) === -1);
    classList.push(
      isVisible ? 'display-always-hidden' : 'display-always-visible'
    );

    Vue.set(field, 'class', classList.join(' '));
  }

  isVisible(field: any) {
    const classList = (field.class || '').split(' ');
    let isVisibleByPriority = true,
        isAlwaysVisible     = false,
        isAlwaysHidden      = false;

    for (const className of classList) {
      if (className === 'display-always-hidden') {
        isAlwaysHidden = true;
      } else if (className === 'display-always-visible') {
        isAlwaysVisible = true;
      } else if (className.indexOf('display-priority-') === 0) {
        const idx = className.split('-').pop();
        isVisibleByPriority = this.windowWidth >= priorityBreakpoints[idx - 1];
      }
    }

    return isAlwaysVisible ? true : isAlwaysHidden ? false : isVisibleByPriority;
  }

  isConditional(field: any): boolean {
    return (field.class || '').split(' ').some((className: string) => className.indexOf('display-priority-') === 0);
  }

  onResize() {
    this.windowWidth = window.innerWidth;
  }

  async created() {
    window.addEventListener('resize', this.onResize);
  }

  async destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  async mounted() {
    this.windowWidth = window.innerWidth;
  }
}
